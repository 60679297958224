// import { ReactDOM } from "react";
import { createPortal } from "react-dom";
import { TiTick as Tick } from "react-icons/ti";
import styles from "./style.module.css";

export default function NotificationToast(props: {
  message: string;
  show: boolean;
}) {
  const { show, message } = props;

  if (show) {
    return createPortal(
      // <div
      //   className={`fixed-bottom m-4 bg-success rounded d-flex align-items-center ${styles["notification-box"]}`}
      //   style={{
      //     width: "300px",
      //     textAlign: "center",
      //     transition: "all ease-in 0.5s",
      //   }}
      // >

      <div
        className={`fixed-bottom m-4 alert alert-success rounded d-flex align-items-center float-right ${styles["notification-box"]}`}
        style={{
          width: "400px",
          textAlign: "center",
          transition: "all ease-in 0.5s",
        }}
      >
        {/* <Tick style={{ fontSize: "3rem", color: "white" }} /> */}
        <Tick style={{ fontSize: "3rem" }} />

        {/* <p className="text-white m-0">{message}</p> */}
        <p className="m-0 font-weight-bold">{message}</p>
      </div>,
      document.getElementById("notification") as HTMLElement
    );
  } else {
    return <></>;
  }
}
