import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  RootState,
  Company,
  Nugget,
  companySource,
  companyTag,
  partnerCompany,
  Tag,
  Flag,
} from "../../utils/types";
import {
  createCompany,
  editCompany,
  getCompanyDetails,
  getCompanyPage,
  removeCompany,
} from "./actions";
import { enableMapSet } from "immer";

enableMapSet();
// const initialState: RootState["company"] = new Map([]);

const initialState: RootState["company"] = {
  status: "idle",
  message: "",
  map: new Map<string, Company>(),
  pagination: { page: 1, pageCount: 0, total: 0 },
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompanyDetails: (state, action: PayloadAction<Company>) => {
      state.map.set(action.payload.id.toString(), action.payload);
    },

    clearCompanyDetails: (state) => {
      state.map.clear();
    },

    updatePageDetails: (
      state,
      action: PayloadAction<{ page: number; pageCount: number; total: number }>
    ) => {
      state.map.clear();
      const { page, pageCount, total } = action.payload;
      state.pagination.page = page;
      state.pagination.pageCount = pageCount;
      state.pagination.total = total;
    },

    updateCompanyNuggets: (
      state,
      action: PayloadAction<{ companyid: number; nugget: Nugget[] }>
    ) => {
      const companydata: any = state.map.get(
        action.payload.companyid.toString()
      );
      companydata.nuggets = action.payload.nugget;
      state.map.set(action.payload.companyid.toString(), companydata);
    },
    updateCompanySources: (
      state,
      action: PayloadAction<{
        companyid: number;
        companySource: companySource[];
      }>
    ) => {
      const companydata: any = state.map.get(
        action.payload.companyid.toString()
      );
      companydata.companySources = action.payload.companySource;
      state.map.set(action.payload.companyid.toString(), companydata);
    },
    updateCompanyTags: (
      state,
      action: PayloadAction<{ companyid: number; companyTag: companyTag[] }>
    ) => {
      const companydata: any = state.map.get(
        action.payload.companyid.toString()
      );
      companydata.companyTags = action.payload.companyTag;
      state.map.set(action.payload.companyid.toString(), companydata);
    },
    updateCompanyPartners: (
      state,
      action: PayloadAction<{ companyid: number; partners: partnerCompany[] }>
    ) => {
      const companydata: any = state.map.get(
        action.payload.companyid.toString()
      );
      companydata.partners = action.payload.partners;
      state.map.set(action.payload.companyid.toString(), companydata);
    },
    updateCompanyFlags: (
      state,
      action: PayloadAction<{ companyid: number; flags: any }>
    ) => {
      const companyData: any = state.map.get(
        action.payload.companyid.toString()
      );
      companyData.companyFlags = action.payload.flags;
      state.map.set(action.payload.companyid.toString(), companyData);
    },

    addCompanyNugget: (
      state,
      action: PayloadAction<{ companyId: number; nugget: Nugget }>
    ) => {
      const { companyId, nugget } = action.payload;
      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.nuggets) {
        companyData.nuggets = [nugget];
      } else {
        companyData.nuggets.push(nugget);
      }
      state.map.set(companyId.toString(), companyData);
    },

    deleteCompanyNugget: (
      state,
      action: PayloadAction<{ nuggetId: number; companyId: number }>
    ) => {
      const { nuggetId, companyId } = action.payload;

      // state.map.set
      const companyData: Company = state.map.get(companyId.toString())!;
      const nuggets = companyData.nuggets;
      const filteredNuggets: Nugget[] = nuggets?.filter(
        (nugget: Nugget) => nugget.id !== nuggetId
      )!;
      companyData.nuggets = filteredNuggets;
      state.map.set(companyId.toString(), companyData);
    },

    addCompanyTag: (
      state,
      action: PayloadAction<{ companyId: number; tag: Tag }>
    ) => {
      const { companyId, tag } = action.payload;
      const companyData: any = state.map.get(companyId.toString());
      if (!companyData.companyTags) {
        companyData.companyTags = [tag];
      } else {
        companyData.companyTags.push(tag);
      }
    },
    deleteCompanyTag: (
      state,
      action: PayloadAction<{ companyId: number; tagId: number }>
    ) => {
      const { companyId, tagId } = action.payload;

      const companyData: any = state.map.get(companyId.toString());
      const tags: Tag[] = companyData.companyTags;

      companyData.companyTags = tags.filter(
        (tag: Tag) => tag.id.toString() !== tagId.toString()
      );

      state.map.set(companyId.toString(), companyData);
    },

    addCompanyKeySource: (
      state,
      action: PayloadAction<{ companyId: number; keySource: companySource }>
    ) => {
      const { companyId, keySource } = action.payload;
      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.companySources) {
        companyData.companySources = [keySource];
      } else {
        companyData.companySources.push(keySource);
      }
    },

    deleteCompanyKeySource: (
      state,
      action: PayloadAction<{ companyId: number; keySourceId: number }>
    ) => {
      const { companyId, keySourceId } = action.payload;
      const companyData: Company = state.map.get(companyId.toString())!;

      const keySources: any = companyData?.companySources;

      companyData.companySources = keySources.filter(
        (source: companySource) =>
          source.id.toString() !== keySourceId.toString()
      );
    },

    editCompanyKeySource: (
      state,
      action: PayloadAction<{ companyId: number; keySource: companySource }>
    ) => {
      const { companyId, keySource } = action.payload;

      const companyData: any = state.map.get(companyId.toString());
      const keySources: companySource[] = companyData?.companySources;
      companyData.companySources = keySources?.map((source: companySource) =>
        source.id.toString() !== keySource.id.toString() ? source : keySource
      );
    },

    addCompanyFlag: (
      state,
      action: PayloadAction<{ companyId: number; flag: Flag }>
    ) => {
      const { companyId, flag } = action.payload;
      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.companyFlags) {
        companyData.companyFlags = [flag];
      } else {
        companyData.companyFlags.push(flag);
      }
    },

    deleteCompanyFlag: (
      state,
      action: PayloadAction<{ companyId: number; flagId: number }>
    ) => {
      const { companyId, flagId } = action.payload;

      const companyData: any = state.map.get(companyId.toString());

      const flags: any = companyData?.companyFlags;

      companyData.companyFlags = flags.filter(
        (flag: Flag) => flag.id !== flagId
      );
    },

    editCompanyFlag: (
      state,
      action: PayloadAction<{ companyId: number; flag: Flag }>
    ) => {
      const { companyId, flag } = action.payload;

      const companyData: any = state.map.get(companyId.toString());
      const flags: any = companyData?.companyFlags;

      companyData.companyFlags = flags.map((f: Flag) =>
        f.id.toString() === flag.id.toString() ? flag : f
      );
    },

    addCompanyPartner: (
      state,
      action: PayloadAction<{ companyId: number; company: Company }>
    ) => {
      const { companyId, company: partner } = action.payload;

      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.partners) {
        companyData.partners = [partner];
      } else {
        companyData.partners.push(partner);
      }
    },

    deleteCompanyPartner: (
      state,
      action: PayloadAction<{ companyId: number; partnerId: number }>
    ) => {
      const { companyId, partnerId } = action.payload;

      const companyData: any = state.map.get(companyId.toString());

      const partners = companyData.partners;

      companyData.partners = partners.filter(
        (company: Company) => company.id !== partnerId
      );
    },

    addCompanyPartnerName: (
      state,
      action: PayloadAction<{ companyId: number; partner: Company }>
    ) => {
      const { companyId, partner } = action.payload;

      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.partners) {
        companyData.partners = [partner];
      } else {
        companyData.partners.push(partner);
      }

      //add new company to main company list
      const { id } = partner;
      state.map.set(id.toString(), partner);
    },

    addCompanyPartnerLink: (
      state,
      action: PayloadAction<{ companyId: number; partner: partnerCompany }>
    ) => {
      const { companyId, partner } = action.payload;

      const companyData: Company = state.map.get(companyId.toString())!;

      if (!companyData.partners) {
        companyData.partners = [partner];
      } else {
        companyData.partners.push(partner);
      }
    },

    deleteCompanyPartnerLink: (
      state,
      action: PayloadAction<{ companyId: number; partnerId: number }>
    ) => {
      const { companyId, partnerId } = action.payload;

      const companyData: any = state.map.get(companyId.toString());
      const partners = companyData.partners;

      companyData.partners = partners.filter((partner: any) => {
        if (partner.id === partnerId && partner.partnerLink) {
          return false;
        } else {
          return true;
        }
      });
    },
    reset: (state) => {
      state.status = "idle";
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createCompany.pending, (state) => {
        state.status = "loading";
      })

      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Company Created Successfully !";
      })

      .addCase(createCompany.rejected, (state, action: any) => {
        state.status = "failed";
        if (action.payload?.response?.data?.error?.code === "ER_DUP_ENTRY") {
          state.message = "Company Already Exists";
        } else {
          state.message = "Something Went Wrong";
        }
      })

      .addCase(removeCompany.pending, (state) => {
        state.status = "loading";
      })

      .addCase(removeCompany.fulfilled, (state, action: any) => {
        state.status = "success";
        const id = action.payload.id;
        const deleted = state.map.delete(String(id));
        state.message = "Company Deleted Successfully";
        // state.status = 'idle'
      })

      .addCase(removeCompany.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(editCompany.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editCompany.fulfilled, (state, action: any) => {
        state.status = "success";
        const id = action.payload.id;
        const companyData: any = state.map.get(id.toString());
        const newCompany = { ...companyData, ...action.payload };
        state.map.set(id.toString(), newCompany);
        state.message = "Company Updated Successfully !";
      })

      .addCase(editCompany.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(getCompanyDetails.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getCompanyDetails.fulfilled, (state) => {
        state.status = "idle";
      })

      .addCase(getCompanyPage.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getCompanyPage.fulfilled, (state) => {
        state.status = "idle";
      });
  },
});

export default company.reducer;

export const {
  updateCompanyDetails,
  updateCompanyNuggets,
  updateCompanySources,
  updateCompanyTags,
  updateCompanyPartners,
  updateCompanyFlags,
  addCompanyNugget,
  deleteCompanyNugget,
  addCompanyTag,
  deleteCompanyTag,
  addCompanyKeySource,
  deleteCompanyKeySource,
  editCompanyKeySource,
  addCompanyFlag,
  deleteCompanyFlag,
  editCompanyFlag,
  addCompanyPartner,
  deleteCompanyPartner,
  addCompanyPartnerName,
  addCompanyPartnerLink,
  deleteCompanyPartnerLink,
  clearCompanyDetails,
  updatePageDetails,
  reset,
} = company.actions;

// export const {updateCompanyDetails, reset} = company.actions
