import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../redux/modules/auth/actions";
import { thirdPartyUserSelector } from "../../redux/modules/thirdPartyUser/selectors";

export default function Menu() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const thirdPartyUser = useSelector(thirdPartyUserSelector);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div className="vertical-nav bg-white" id="sidebar">
      <h1 className="logo">
          Wendwise
          <sup className="tm">TM</sup>
        </h1>
        <ul className="nav flex-column bg-white mb-0 px-0">
          <li className="nav-item">
            <NavLink
              to="home"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-home mr-3 fa-fw"></i>
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="requests"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-commenting-o mr-3 fa-fw"></i>
              My Requests
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="activity"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-line-chart mr-3 fa-fw"></i>
              Activity
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <NavLink
              to="flagged"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-flag-o mr-3 text-dark fa-fw"></i>Flagged
            </NavLink>
          </li>

          <li className="nav-item">
            <a
              href="#pageSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="pageSubmenu"
              className="dropdown-toggle nav-link text-dark"
            >
              <i className="fa fa-cog mr-3 text-dark fa-fw"></i>Settings
            </a>
            <ul className="nav collapse" id="pageSubmenu">
            { 
              thirdPartyUser && 
              (
                <li className="nav-item">
                    <NavLink
                      to="settings"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link text-white bg-primary"
                          : "nav-link text-dark"
                      }
                    >
                      <i className="fa fa-user mr-3 text-dark fa-fw"></i>User
                      Settings
                    </NavLink>
                </li>
              )
            }
              <li className="nav-item">
                <NavLink
                  to="notifications"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white bg-primary"
                      : "nav-link text-dark"
                  }
                >
                  <i className="fa fa-bell-o mr-3 text-dark fa-fw"></i>
                  Notifications
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  className="nav-link text-dark"
                  onClick={handleLogout}
                >
                  <i className="fa fa-power-off mr-3 text-dark fa-fw"></i>Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  );
}
