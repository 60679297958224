import { MouseEventHandler } from "react";
import { partnerCompany } from "../../redux/utils/types";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { Contributors } from "../Contributors";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function KeyPartners(props: {
  selectTab: MouseEventHandler<any>;
  keyPartners: partnerCompany[];
  contributors?: string[];
}) {
  const { selectTab, keyPartners, contributors } = props;

  const latestUpdate: any = getLatestUpdate(keyPartners);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }
  // bug of time showing while creating company
  const { pathname } = useLocation();
  //view will be either new or view
  const view = pathname.split("/")[2];
  const isHomeView = pathname.includes('/home/view');
  const isFlaggedView = pathname.includes('/flagged/view');

  return (
    <div className="key-partners mt-3">
      <div className="card w-100">
        <div className="card-body">
          <div className="top">
            <div className="top-start">
              <div>
                <h5 className="card-title">Partners&nbsp;</h5>
              </div>
              <div>
                <>{view === "view" && <Time time={lastUpdate} />}</>
              </div>
            </div>
            <div className="top-end">
              <div>
                <span role="button" onClick={selectTab}>
                  <img src="/img/Vector.png" className="pl-3" alt="" />
                </span>
              </div>
            </div>
          </div>

          <div className="bg mt-2">
            <div className="bg-inner scroll-tag mt-2">
              {keyPartners.map((partner: any, index: number) => (
                <p
                  key={index}
                  className="p-2 border rounded my-3"
                  style={{ backgroundColor: "lightgrey" }}
                >
                   {isHomeView ? (
                    <>
                      <Link to={`/home/view/${partner.id}`}>
                        {partner.name || partner.companyName || partner.partnerLink}
                      </Link>
                    </>
                  ) : (
                    isFlaggedView ? (
                      <Link to={`/flagged/view/${partner.id}`}>
                        {partner.name || partner.companyName || partner.partnerLink}
                      </Link>
                    ) : (
                      <Link to={`/companies/view/${partner.id}`}>
                        {partner.name || partner.companyName || partner.partnerLink}
                      </Link>
                    )
                  )}
                  {/* {isHomeView ? (
                    <Link to={`/home/view/${partner.id}`}>
                      {partner.name || partner.companyName || partner.partnerLink}
                    </Link>
                  ) : (
                    <Link to={`/companies/view/${partner.id}`}>
                      {partner.name || partner.companyName || partner.partnerLink}
                    </Link>
                  )} */}
                </p>
              ))}
            </div>
            <div className="bg-inner mt-2">
              <p className="key">
                Key Contributors &gt;
                <span>
                  <Contributors contributors={contributors ?? []} />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}