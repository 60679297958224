import axios from "../.././../adapters/axios";

export const httpGetCompanies = async () => {
  const response = await axios.get("companies");
  return await response.data;
};

export const httpPostCompany = async (company: any) => {
  const response = await axios.post("companies", company);
  return response.data;
};

export const httpDeleteCompany = async (id: string) => {
  return await axios.delete(`companies/${id}`);
};

export const httpGetNuggets = async (companyId: number) => {
  const response = await axios.get(`companies/${companyId}/nuggets`);
  return response.data;
};

export const httpGetCompanyTags = async (companyId: number) => {
  const response = await axios.get(`companies/${companyId}/tags`);
  return response.data;
};

export const httpGetCompanySources = async (companyId: number) => {
  const response = await axios.get(`companies/${companyId}/company-sources`);
  return response.data;
};

export const httpGetCompanyFlags = async (companyId: number) => {
  const response = await axios.get(`companies/${companyId}/flags`);
  return response.data;
};

export const httpGetPartnerCompanies = async (companyId: number) => {
  const response = await axios.get(`companies/${companyId}/partner-companies`);
  return response.data;
};

export const httpEditCompany = async (companyId: number, data: any) => {
  const response = await axios.patch(`companies/${companyId}`, data);
  return response.data;
};

export const httpGetCompanyPage = async (
  pageNumber: number,
  {
    keyword = "",
    tags = [],
    flagged = false,
    inSearch = false,
  }: { keyword: string; tags: number[]; flagged?: boolean; inSearch?: boolean }
) => {
  const response = await axios.post(`companies/paginate/${pageNumber}`, {
    keyword,
    tags,
    flagged,
    inSearch,
  });
  return response.data;
};

export const httpGetCompanyById = async (id: number) => {
  const response = await axios.get(`companies/${id}`);
  return response.data;
};

//not used
export const putCompany = async (companyId: number, company: any) => {
  const response = await axios.put(`companies/${companyId}`, company);
  return response.data;
};
