import { AppThunk } from "../../store";
import { Company } from "../../utils/types";
import {
  updateCompanyDetails,
  updateCompanyNuggets,
  updateCompanyTags,
  updateCompanySources,
  updateCompanyFlags,
  updateCompanyPartners,
  clearCompanyDetails,
  updatePageDetails,
} from "./slice";
import {
  httpGetCompanies,
  httpGetNuggets,
  httpGetCompanySources,
  httpGetCompanyTags,
  httpGetPartnerCompanies,
  httpDeleteCompany,
  httpGetCompanyFlags,
  httpEditCompany,
  httpPostCompany,
  httpGetCompanyPage,
  httpGetCompanyById,
} from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyModel from "../../../models/company";
// export const getCompanies = (): AppThunk => async (dispatch) => {
//   try {
//     const companies: any = await httpGetCompanies();

//     for (let company of companies) {
//       company.companyTags = company.tags;
//       delete company.tags;
//       dispatch(updateCompanyDetails(company));
//     }
//   } catch (err) {
//     console.error(err);
//   }
// };

export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (_, thunkAPI) => {
    try {
      const companies: any = await httpGetCompanies();
      for (let company of companies) {
        company.companyTags = company.tags;
        delete company.tags;
        thunkAPI.dispatch(updateCompanyDetails(company));
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCompany = createAsyncThunk(
  "company/create",
  async (companyData: any, thunkAPI) => {
    try {
      const company: Company = await httpPostCompany(companyData);
      thunkAPI.dispatch(getCompanyDetails(company.id));
      thunkAPI.dispatch(updateCompanyDetails(company));
      // thunkAPI.dispatch<any>(getCompanies())
      return company;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const removeCompany = createAsyncThunk(
  "company/delete",
  async (id: string, thunkAPI) => {
    try {
      const response = await httpDeleteCompany(id);

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editCompany = createAsyncThunk(
  "company/edit",
  async (data: { companyId: number; company: any }, thunkAPI) => {
    const { companyId, company } = data;
    try {
      // const response = await putCompany(companyId, company)
      const response = await httpEditCompany(companyId, company);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  "company/getDetails",
  async (companyId: number, { getState, dispatch }) => {
    try {
      // await Promise.all([
      //   httpGetNuggets(companyId).then((nugget) =>
      //     dispatch(updateCompanyNuggets({ companyid: companyId, nugget }))
      //   ),

      //   httpGetCompanyTags(companyId).then((companyTag) =>
      //     dispatch(updateCompanyTags({ companyid: companyId, companyTag }))
      //   ),

      //   httpGetCompanySources(companyId).then((companySource) =>
      //     dispatch(
      //       updateCompanySources({ companyid: companyId, companySource })
      //     )
      //   ),

      //   httpGetPartnerCompanies(companyId).then((partners) =>
      //     dispatch(updateCompanyPartners({ companyid: companyId, partners }))
      //   ),

      //   httpGetCompanyFlags(companyId).then((flags) =>
      //     dispatch(updateCompanyFlags({ companyid: companyId, flags }))
      //   ),
      // ]);

      // const company = await httpGetCompanyById(companyId);
      // const partners = await httpGetPartnerCompanies(companyId);

      const [company, partners, tags] = await Promise.all([
        httpGetCompanyById(companyId),
        httpGetPartnerCompanies(companyId),
        httpGetCompanyTags(companyId),
      ]);

      const {
        id,
        companyName,
        flagColor,
        companyLink,
        createdAt,
        updatedAt,
        createdBy,
        updatedBy,
        // tags = [],
        flags = [],
        companysource = [],
        partnerCompanies = [],
        nuggets = [],
      } = company;

      const mappedCompany = CompanyModel(
        id,
        companyName,
        flagColor,
        companyLink,
        createdAt,
        updatedAt,
        createdBy,
        updatedBy,
        nuggets,
        companysource,
        tags,
        flags,
        partners
      );
      dispatch(updateCompanyDetails(mappedCompany));
    } catch (err) {
      console.log(err);
    }
  }
);

export const getCompanyPage = createAsyncThunk(
  "companies/getPage",
  async (
    payload: {
      pageNumber: number;
      keyword: string;
      tags: number[];
      flagged?: boolean;
      inSearch?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const {
        pageNumber,
        keyword = "",
        tags = [],
        flagged = false,
        inSearch = false,
      } = payload;
      const companies: any = await httpGetCompanyPage(pageNumber, {
        keyword,
        tags,
        flagged,
        inSearch,
      });
      const { data, page, pageCount, total } = companies;
      // thunkAPI.dispatch(clearCompanyDetails());
      thunkAPI.dispatch(updatePageDetails({ page, pageCount, total }));

      for (let company of data) {
        const {
          id,
          companyName,
          flagColor,
          companyLink,
          createdAt,
          updatedAt,
          createdBy,
          updatedBy,
          tags = [],
          flags = [],
          companysource = [],
          partnerCompanies = [],
          nuggets = [],
        } = company;

        const mappedCompany = CompanyModel(
          id,
          companyName,
          flagColor,
          companyLink,
          createdAt,
          updatedAt,
          createdBy,
          updatedBy,
          nuggets,
          companysource,
          tags,
          flags,
          partnerCompanies
        );
        thunkAPI.dispatch(updateCompanyDetails(mappedCompany));
      }
    } catch (err) {
      console.error(err);
    }
  }
);
