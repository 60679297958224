import { MouseEventHandler, useState, useEffect } from "react";
import { Company, partnerCompany } from "../../redux/utils/types";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addMultiplePartners,
  addPartner,
  addPartnerLink,
  addPartnerName,
  deletePartner,
  deletePartnerLink,
} from "../../redux/modules/companyDetails/actions";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import { useSelector } from "react-redux";
import NotificationToast from "../NotificationToast";
import { reset } from "../../redux/modules/companyDetails/slice";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { Contributors } from "../Contributors";
import { FiExternalLink as LinkIcon } from "react-icons/fi";
import { linkify } from "../ViewAddKeySources/utils";
import { authSelector } from "../../redux/modules/auth/userSelector";

import KeyPartnersPrompt from "../KeyPartnersPrompt";
import CompanyNamePrompt from "../CompanyNamePrompt";
import CompanyLinkPrompt from "../CompanyLinkPrompt";

type Prompt = "company" | "name" | "link" | "none";

export default function ViewAddKeyPartners(props: {
  closeTab: MouseEventHandler<any>;
  keyPartners: partnerCompany[];
  setKeyPartners: any;
  contributors?: string[];
}) {
  const { closeTab, keyPartners, setKeyPartners, contributors } = props;
  // const [isPrompt, setIsPrompt] = useState<boolean>(false);

  const [prompt, setPrompt] = useState<Prompt>("none");

  const { pathname } = useLocation();

  const { user } = useSelector(authSelector);

  const companyId = +pathname.split("/")[3];

  const [isLoading, setIsLoading] = useState(false);

  const [newPartners, setNewPartners] = useState<
    {
      type: string;
      data: any;
    }[]
  >([]);

  const dispatch = useDispatch<any>();

  const handleAddKeyPartner = (partners: any[]) => {
    setPrompt("none");
    setNewPartners(partners);
  };
  const handleCancelNewPartner = (index: number) => {
    const newPartnerList = newPartners.filter(
      (_: any, i: number) => i !== index
    );
    setNewPartners(newPartnerList);
  };

  const handleSavePartner = async (partner: any, index: number) => {
    setIsLoading(true);

    if (partner.id) {
      //company already present in db
      dispatch(addPartner({ companyId, partnerId: partner.id }));
      dispatch(addPartner({ companyId: partner.id, partnerId: companyId }));
    } else if (partner.companyName) {
      //add partner by name request
      dispatch(addPartnerName({ companyId, companyName: partner.companyName }));
    } else if (partner.partnerLink) {
      dispatch(addPartnerLink({ companyId, companyLink: partner.partnerLink }));
      //add partner link request
    }

    const newPartnerList = newPartners.filter(
      (partner: any, i: number) => i !== index
    );
    setNewPartners(newPartnerList);
  };

  const { status, message } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);
  const latestUpdate = getLatestUpdate(keyPartners);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  const handleSaveAllPartners = async () => {
    //if this handler is running is running
    //then for sure partner companies exist in the database

    const partnerIds = newPartners.map((partner: any) => partner.id);
    setIsLoading(true);

    await dispatch(addMultiplePartners({ companyId, partnerIds }));

    setNewPartners([]);
  };
  return (
    <>
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Key Partners&nbsp;</h5>
                        </div>
                        <div>
                          <Time time={lastUpdate} />
                        </div>
                      </div>
                      <div className="top-end">
                        {user.role === "user" && (
                          <div>
                            <Link
                              to="suggestion/partner"
                              className="btn suggest-btn"
                            >
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <div className="bg-inner mt-2">
                        <KeyPartnerList keyPartners={keyPartners} />
                        <div>
                          {newPartners.length > 1 && (
                            <div className="text-right m-4">
                              <button
                                className="btn btn-success"
                                onClick={handleSaveAllPartners}
                              >
                                Save All
                              </button>
                            </div>
                          )}
                          {newPartners.map((partner: any, index: any) => (
                            <div className="my-3 d-flex" key={index}>
                              <p className="border rounded w-75 p-2 bg-white m-0">
                                {partner?.companyName || partner?.partnerLink}
                              </p>
                              <button
                                className="btn btn-success mx-2"
                                onClick={() =>
                                  handleSavePartner(partner, index)
                                }
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-secondary mx-2"
                                onClick={(_) => handleCancelNewPartner(index)}
                              >
                                Cancel
                              </button>
                              {isLoading && (
                                <Spinner
                                  style={{ width: "50px", height: "50px" }}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="bg-inner mt-2">
                        <div className="top">
                          <div className="top-start">
                            <p className="key">
                              Key Contributors &gt;
                              <span>
                                <Contributors
                                  contributors={contributors ?? []}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {user.role === "admin" && (
                        <div className="text-right my-4">
                          <span
                            className="btn key-btn"
                            role="button"
                            onClick={() => setPrompt("company")}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Key Partners
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationToast message={message} show={status === "success"} />
      {/* prompts */}
      <>
        <KeyPartnersPrompt
          show={prompt === "company"}
          handleCancel={() => setPrompt("none")}
          handleOk={handleAddKeyPartner}
          selectedPartners={keyPartners}
          setPrompt={setPrompt}
          currentCompanyId={companyId}
        />

        <CompanyNamePrompt
          show={prompt === "name"}
          setPrompt={setPrompt}
          handleOk={handleAddKeyPartner}
        />

        <CompanyLinkPrompt
          show={prompt === "link"}
          setPrompt={setPrompt}
          handleOk={handleAddKeyPartner}
        />
      </>
    </>
  );
}

function KeyPartnerList(props: { keyPartners: partnerCompany[] }) {
  const { keyPartners } = props;
  return (
    <>
      {keyPartners.map((keyPartner: partnerCompany) => (
        <KeyPartnerItem key={keyPartner.id} keyPartner={keyPartner} />
      ))}
    </>
  );
}

function KeyPartnerItem(props: { keyPartner: partnerCompany }) {
  const { keyPartner } = props;
  const { pathname } = useLocation();
  const { user } = useSelector(authSelector);

  const companyId = Number(pathname.split("/")[3]);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<any>();

  const url = linkify(keyPartner.partnerLink || "");

  const handleRemovePartner = (keyPartnerId: number) => {
    setIsLoading(true);

    if (keyPartner.partnerLink) {
      dispatch(deletePartnerLink({ companyId, partnerId: keyPartner.id }));
    } else {
      const payload = { companyId, partnerId: keyPartnerId };

      dispatch(deletePartner(payload));
    }
  };

  const { status } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
    }
  }, [status]);

  return (
    <div className="my-3 d-flex align-items-center">
      <p className="border rounded w-75 p-2 bg-white list__test">
        {keyPartner.companyName ?? keyPartner.partnerLink}
      </p>
      {url && (
        <a href={url} target="_blank" rel="noreferrer" title="Visit">
          <LinkIcon className="h4 mx-2" />
        </a>
      )}
      {user.role === "admin" && (
        <div className="m-2" onClick={() => handleRemovePartner(keyPartner.id)}>
          <Cross className="text-danger h4" role="button" />
        </div>
      )}
      {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
    </div>
  );
}
