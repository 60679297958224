import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action
} from '@reduxjs/toolkit'
import users from './modules/users/slice'
import company from './modules/company/slice'
import notifications from './modules/notifications/slice'
import currentUser from './modules/currentUser/slice'
import tags from './modules/tags/slice'
import requests from './modules/requests/slice'
import { RootState } from './utils/types'
import auth from './modules/auth/slice'
import companyDetails from './modules/companyDetails/slice'
import { enableMapSet } from 'immer'
import thirdPartyUser from './modules/thirdPartyUser/slice'

enableMapSet()

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  })
]

export const store = configureStore({
  reducer: {
    users,
    company,
    notifications,
    currentUser,
    requests,
    tags,
    auth,
    companyDetails,
    thirdPartyUser
  },
  middleware,
  devTools: { serialize: { options: true } }
  // serialize: { options: { map: true } }
})

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
